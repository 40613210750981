<form [formGroup]="form">
  <label>
    Enter a Password:
    <input
      pwnedPasswordValidator
      [ngClass]="{ 'is-invalid': (!pw.pristine && !pw.pending && pw.errors), 'is-valid':  (!pw.pristine && !pw.pending && !pw.errors) }"
      type="password" formControlName="password">

  </label>

</form>

<div *ngIf="!pw.pending && pw.errors && pw.errors.pwnedPasswordOccurrence" class="invalid-feedback">
  <h2>This password has been seen <span class="invalid-feedback--highlight">{{pw.errors.pwnedPasswordOccurrence | number:'1.0-0' }}</span> times before</h2>
  <p>This password has previously appeared in a data breach and should never be used. If you've ever used it anywhere before, change it!
  </p>
  <p>We are checking against 847 million real world passwords previously exposed in data breaches. This exposure makes them unsuitable for ongoing use as they're at much greater risk of being used to take over other accounts. <a href="https://www.troyhunt.com/ive-just-launched-pwned-passwords-version-2/#cloudflareprivacyandkanonymity"> Read more about how the privacy of searched passwords is protected.</a>
  </p>
</div>
<div *ngIf="!pw.pending && !pw.errors" class="valid-feedback">
  <h2>Great Password!</h2>
  <p>This password has not yet been breached, feel free to use it.
  </p>
  <p>We are checking against 847 million real world passwords previously exposed in data breaches. This exposure makes them unsuitable for ongoing use as they're at much greater risk of being used to take over other accounts. <a href="https://www.troyhunt.com/ive-just-launched-pwned-passwords-version-2/#cloudflareprivacyandkanonymity"> Read more about how the privacy of searched passwords is protected.</a>
  </p>
</div>
